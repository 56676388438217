@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

:root {
  --black-text: #001;
  --dark-blue: #98e;
  --dark-purple: #c4aaf2;
  --dark-red: #da2834;
  --greentext: #789922;
  --light-blue: #d6daf0;
  --light-gray: #ccc;
  --light-green: #c9ffc6;
  --light-purple: #d5c5f2;
  --light-red: #ffc6c9;
  --lightest-blue: #e6eafa;
  --medium-blue: #c5ccf2;
  --medium-gray: #aaa;
  --post-author-green: #117743;
  --post-subject-blue: #0f1367;
  --white-text: #eef;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--dark-red);
  text-decoration: none;
}

a:hover {
  font-weight: bold;
}

body {
  background: var(--light-gray);
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
}

button {
  background: var(--dark-purple);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 4px 8px 4px 8px;
}

button:disabled {
  background: var(--light-red);
}

button:disabled:hover {
  outline: none;
}

button:focus,
button:hover {
  outline: 1px solid var(--black-text);
}

footer {
  align-items: center;
  background: var(--dark-blue);
  display: flex;
  font-size: 0.85rem;
  justify-content: space-between;
  padding: 4px;
}

footer span a {
  font-weight: bold;
}

footer a:hover {
  text-decoration: underline;
}

header {
  align-items: center;
  background: var(--dark-blue);
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;
  padding: 4px;
  position: sticky;
  top: 0;
  z-index: 9;
}

@media (max-width: 358px) {
  header {
    flex-direction: column;
  }
}

header a,
footer a {
  color: var(--black-text);
  text-decoration: none;
}

header a:hover,
header a:focus {
  font-weight: bold;
  text-decoration: none;
}

header nav {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

header nav img {
  height: 32px;
}

header nav > * {
  align-items: center;
  background: var(--light-purple);
  display: flex;
  gap: 8px;
  padding: 0 12px 0 12px;
}

header nav > *:hover,
header nav > *:focus {
  outline: 1px solid var(--black-text);
}

header .account {
  align-items: center;
  background: var(--light-purple);
  display: flex;
  gap: 8px;
  padding: 0px 8px 0px 8px;
  position: relative;
  text-decoration: none;
}

header .login {
  padding: 6px 12px 6px 12px;
}

header .account img {
  height: 30px;
  margin: 2px;
}

header .account:focus,
header .account:hover {
  cursor: pointer;
  outline: 1px solid var(--black-text);
}

header .arrow {
  transition: transform 300ms;
}

header .arrow.rotate {
  transform: rotate(-0.25turn);
}

header .current-location {
  font-size: 1.05rem;
}

header .menu {
  background: var(--light-purple);
  box-shadow: 0 2px 4px var(--light-gray);
  gap: 16px;
  left: 0;
  outline: 1px solid var(--black-text);
  padding: 4px 8px 4px 8px;
  position: absolute;
  top: 35px;
  width: 100%;
}

header .menu button {
  background: none;
  border: none;
  font-size: 1rem;
  outline: none;
  padding: 0;
}

header .menu button:hover,
header .menu button:focus {
  cursor: pointer;
  font-weight: bold;
}

header .menu.hidden {
  display: none;
}

header .menu ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

input,
textarea {
  border: 2px solid var(--light-gray);
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  outline: none;
}

input:focus,
textarea:focus {
  border: 2px solid var(--dark-blue);
}

input:invalid,
textarea:invalid {
  border: 2px solid var(--light-red);
}

.about-container,
.about-cheddit {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about-cheddit {
  padding: 4px;
}

.about-cheddit li {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.about-cheddit ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about-cheddit img {
  height: 24px;
}

.board {
  position: relative;
  width: 100%;
}

.board h1,
.thread h1,
.login h1,
.invalid-route h1,
.about-container h1 {
  background: var(--medium-blue);
  font-size: 1.1rem;
  padding: 4px;
}

.board .thread-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.board .thread-container hr {
  width: 100%;
}

.board .thread-info {
  background: var(--medium-blue);
  border-top: 1px solid var(--medium-gray);
  padding: 4px 4px 4px 8px;
}

.board .thread-info.sticky {
  background: var(--light-purple);
}

.board .thread-op {
  display: flex;
  flex-direction: column;
}

.board .thread-replies {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.boards,
.site-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
  padding: 4px;
}

.button.hidden {
  display: none;
}

.container {
  background: var(--lightest-blue);
  box-shadow: 0 4px 4px 4px var(--medium-gray);
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: auto;
  max-width: 900px;
  min-height: 100vh;
}

.dashboard .auth-info {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.dashboard .change-displayname {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.dashboard .change-displayname label {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.dashboard .change-displayname button {
  width: 146px;
}

.dashboard .error {
  max-width: 300px;
  height: auto;
  padding: 4px 8px 4px 8px;
  width: 100%;
}

.dashboard-loading,
.header-loading,
.home-loading {
  align-items: center;
  display: flex;
  gap: 8px;
}

.dashboard-loading,
.home-loading {
  padding: 4px;
}

.disclaimer {
  align-items: flex-start;
  background: var(--medium-blue);
  box-shadow: 0 2px 4px var(--light-gray);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
}

.disclaimer h2 {
  background: var(--dark-red);
  color: var(--white-text);
  font-size: 1.15rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.disclaimer.hidden {
  display: none;
  visibility: hidden;
}

.disclaimer-buttons {
  display: flex;
  gap: 8px;
}

.disclaimer-buttons button {
  width: 150px;
}

.disclaimer-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

.error {
  background: var(--light-red);
  padding: 4px;
}

.error.hidden {
  visibility: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.home h1,
.dashboard h1,
.rules h1 {
  background: var(--medium-blue);
  font-size: 1.1rem;
  padding: 4px;
  width: 100%;
}

.invalid-route > div {
  padding: 4px;
}

.invalid-route .invalid-message {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.login .google-login {
  align-items: center;
  display: flex;
  gap: 8px;
}

.login .login-buttons {
  display: flex;
  gap: 8px;
}

.login .other-options {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

.login .other-options img {
  background: var(--white-text);
  border-radius: 50%;
  padding: 4px;
  height: 28px;
}

.login .create-account {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.login form,
.login form label {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
} 

.login form {
  padding: 4px;
}

.new-board-form {
  align-items: flex-start;
  background: var(--light-blue);
  box-shadow: 0 2px 4px var(--light-gray);
  gap: 8px;
  padding: 4px;
  width: 100%;
}

.new-board-form h2 {
  font-size: 1rem;
  font-weight: bold;
}

.new-board-form.hidden {
  display: none;
  visibility: hidden;
}

.new-bord-form.visible {
  display: flex;
  visibility: visible;
}

.new-board-form .add-rule-button {
  width: 146px;
}

.new-board-form,
.new-board-form label {
  display: flex;
  flex-direction: column;
}

.new-board-form label {
  max-width: 300px;
  width: 100%;
}

.new-board-form .description {
  font-style: italic;
}

.new-board-form > div:last-child {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.new-board-form > div:last-child div {
  align-items: center;
  display: flex;
  gap: 8px;
}

.new-board-form > div:last-child button {
  width: 50%;
}

.post {
  background: var(--medium-blue);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  max-width: 1200px;
}

.post .mod-badge {
  font-size: 1.2rem;
}

.post .mod-info {
  align-items: center;
  color: var(--dark-red);
  display: flex;
  font-weight: bold;
  gap: 4px;
}

.post input {
  border: none;
  height: auto;
  max-width: 100%;
}

.post input:focus,
.post input:hover {
  outline: 1px solid var(--black-text);
}

.post p {
  word-break: break-word;
  width: 100%;
}

.post .greentext {
  color: var(--greentext);
}

.post .linked-posts {
  display: flex;
  gap: 4px;
}

.post .sticky-info {
  align-items: center;
  color: var(--white-text);
  background: var(--dark-red);
  display: flex;
  gap: 16px;
  justify-content: center;
}

.post .sticky-info img {
  filter: invert(1);
  height: 32px;
}

.post-content {
  align-items: flex-start;
  display: flex;
  gap: 8px;
}

@media (max-width: 600px) {
  .post-content {
    flex-direction: column;
  }
}

.post.reply {
  background: var(--light-blue);
}

.post.sticky {
  background: var(--light-purple);
}

.post [aria-label="post content"] {
  display: flex;
  flex-direction: column;
}

.post-control {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.post-control .delete-confirm {
  background: var(--dark-red);
  color: var(--white-text);
}

.post-control .delete-post.hidden,
.post-control .confirm.hidden {
  display: none;
  visibility: hidden;
}

.post-control .delete-post.visible, 
.post-control .confirm.visible {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  visibility: visible;
}

.post-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.post-info [aria-label="author"] {
  font-weight: bold;
  color: var(--post-author-green);
}

.post-info [aria-label="subject"] {
  color: var(--post-subject-blue);
  font-weight: bold;
}

.post-info [aria-label="timestamp"] {
  font-style: italic;
}

.reply-container,
.new-thread-container {
  align-items: flex-start;
  background: var(--light-blue);
  box-shadow: 0 2px 2px var(--medium-gray);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 4px 8px 4px;
  position: sticky;
  top: 40px;
}

.reply-container h2,
.new-thread-container h2 {
  font-size: 1.05rem;
}

.reply-form,
.thread-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
  width: 100%;
}

.reply-form .buttons,
.thread-form .buttons {
  display: flex;
  gap: 8px;
}

.reply-form .buttons.hidden,
.thread-form .buttons.hidden {
  display: none;
  visibility: hidden;
}

.reply-form .file-input,
.thread-form .file-input {
  display: none;
  border: none;
}

.reply-form .custom-file-input,
.thread-form .custom-file-input {
  background: var(--dark-purple);
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  width: max-content;
}

.reply-form .file-input-container,
.thread-form .file-input-container {
  align-items: center;
  display: flex;
  gap: 8px;
}

.reply-form .file-input:focus,
.thread-form .file-input:focus {
  outline: 2px solid var(--dark-blue);
}

.reply-form .loading.hidden,
.thread-form .loading.hidden {
  display: none;
  visibility: hidden;
}

.reply-form .loading,
.thread-form .loading,
.invalid-route .loading,
.user-boards .loading {
  align-items: center;
  display: flex;
  gap: 8px;
}

.reply-form .loading img,
.thread-form .loading img,
.dashboard-loading img,
.invalid-route .loading img,
.user-boards .loading img,
.home .home-loading img{
  height: 32px;
}

.reply-form > *,
.thread-form > * {
  max-width: 400px;
  width: 100%;
}

.reply-form label,
.thread-form label {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rules .board-rules,
.rules .global-rules {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 4px;
}

.rules .per-board-rules h1 {
  align-items: center;
  display: flex;
  gap: 16px;
}

.rules .per-board-rules li.editing-rule {
  display: list-item;
  max-width: 300px;
  width: 100%;
}

.rules .per-board-rules li button {
  width: 100%;
}

.rules .per-board-rules li textarea {
  width: 100%;
}

.rules .per-board-rules .delete-rule {
  color: var(--white-text);
  background: var(--dark-red);
}

.rules .per-board-rules .edit-rules-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
  width: 100%;
}

.rules .per-board-rules .edit-rules-control > div {
  display: flex;
  gap: 8px;
  max-width: 300px;
  width: 100%;
}

.rules .per-board-rules .edit-rules-control > div button {
  width: 50%;
}

ol {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

ol > li::marker {
  font-weight: bold;
}

.thread,
.board {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.user-board {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.user-board > span:first-child {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
}

.user-board .confirm {
  width: 100%;
}

.user-board .confirm .confirm-buttons {
  display: flex;
  gap: 8px;
}

.user-board .confirm .confirm-buttons button {
  width: 50%;
}

.user-board .confirm .confirm-buttons .confirm-delete {
  color: var(--white-text);
  background: var(--dark-red);
}

.user-board .confirm.hidden {
  display: none;
}

.user-board .delete-icon {
  border: none;
  background: var(--dark-purple);
  height: 32px;
}

.user-board .delete-icon.hidden {
  visibility: hidden;
}

.user-board .delete-icon:hover,
.user-board .delete-icon:focus {
  outline: 1px solid var(--black-text);
}

.user-boards,
.user-threads,
.user-posts {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
}

.user-threads,
.user-posts {
  gap: 16px;
}

.user-boards button:first-child {
  width: 146px;
}

.valid {
  background: var(--light-green);
  padding: 4px;
}
